/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Fragment, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getPaiementInfo } from "../api/paiementApi";
import { Menu, Transition } from "@headlessui/react";
import { EyeIcon, TrashIcon } from "@heroicons/react/solid";
import moment from "moment";
import DetailPaiementModal from "./modals/DetailPaiementModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let coef = 0.68;

const DetailPaiement = () => {
  const { id } = useParams();

  const [detailPaiement, setDetailPaiement] = useState({});
  const [open, setOpen] = useState(false);
  const [detailClient, setDetailClient] = useState({});

  const getDetail = async () => {
    await getPaiementInfo(id).then((res) => {
      console.log("res.data", res.data);
      setDetailPaiement(res.data);

      let ids = [];
      if (detailPaiement)
        detailPaiement?.tab?.map((t) => {
          ids.push({ id: t.facebook_Id, name: t.facebook_name });
        });
      console.log("ids", ids);
    });
  };
  console.log("detailPaiement", detailPaiement);
  useEffect(() => {
    getDetail();
  }, []);

  const statuses = {
    Paid: "text-green-700 bg-green-50 ring-green-600/20",
    Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
    Overdue: "text-red-700 bg-red-50 ring-red-600/10",
  };

  return (
    <div className="px-10">
      <div className="m-10">
        <div className="text-center mt-10 text-3xl font-bold">
          Période de Paiement
          <span>
            {": "}
            {moment(detailPaiement.payment_date).format("MMMM-YYYY")}{" "}
          </span>
        </div>
        <div className="text-md text-center">
          Montant Total : {detailPaiement?.payment_amount}
        </div>
      </div>
      <div className="bg-slate-900 px-4 rounded-lg">
        <div className="mt-8 flow-root ">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-700">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                    >
                      Facebook Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                    >
                      Montant $
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="px-3 py-3.5 text-center text-sm font-semibold text-white">
                        Actions
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {detailPaiement?.tab?.map((pay) => (
                    <tr key={pay.remittance + pay.facebook_name}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {pay.facebook_name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {pay?.remittance} {detailPaiement.payment_currency}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {pay.statut ? "payé" : "Non Payé"}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <div className="grid grid-cols-2">
                          <button
                            onClick={() => {
                              setOpen(true);
                              setDetailClient(pay);
                            }}
                            className="text-indigo-400 hover:text-indigo-300"
                          >
                            <EyeIcon className="text-blue-400 h-6" />
                            <span className="sr-only"> {pay.name}</span>
                          </button>
                          <button className="">
                            <TrashIcon className="text-red-500 h-6" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ul
          role="list"
          className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8 xl:grid-cols-3 xl:gap-x-8"
        >
          {!!detailPaiement &&
            detailPaiement?.tab?.map((client) => (
              <li
                key={client.remittance}
                className="overflow-hidden rounded-xl border  border-slate-500"
              >
                <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-3">
                  <img
                    // src={client.imageUrl}
                    src="https://tailwindui.com/img/logos/48x48/tuple.svg"
                    alt={client.facebook_name}
                    className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                  />
                  <div className="text-sm font-medium leading-6 text-gray-900">
                    {client.facebook_name}
                  </div>
                  <Menu as="div" className="relative ml-auto">
                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                      <span className="sr-only">Open options</span>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="#"
                              className={classNames(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              View
                              <span className="sr-only">
                                , {client.facebook_name}
                              </span>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="#"
                              className={classNames(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              Edit
                              <span className="sr-only">
                                , {client.facebook_name}
                              </span>
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Période </dt>
                    <dd className="text-gray-700">
                      <time dateTime={client.payout_period_start}>
                        {client.payout_period_start} à{" "}
                        {client.payout_period_end}
                      </time>
                    </dd>
                  </div>
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Montant</dt>
                    <dd className="flex items-start gap-x-2">
                      <div className="font-medium text-gray-900">
                        {client.remittance} $
                      </div>
                      <div
                        className={classNames(
                          statuses[!!client.remittance],
                          "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                        )}
                      >
                        A payé {(client.remittance * coef).toFixed(2)} €
                      </div>
                    </dd>
                  </div>
                </dl>
              </li>
            ))}
        </ul>
      </div>

      <DetailPaiementModal
        client={detailClient}
        coef={coef}
        open={open}
        setOpen={setOpen}
        statuses={statuses}
      />
    </div>
  );
};

export default DetailPaiement;
