import { Button, Form, Image, Row, Switch } from "antd";

import youtubelogo from "../assets/logo-Youtube.png";
import moment from "moment";
import { SaveOutlined } from "@ant-design/icons";

export default function YoutubeView({ data, form, updateStatus, setOpenView }) {
  console.log("data", data);

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-0 sm:px-6 ">
        <div className="w-full mx-auto flex justify-center">
          <Image width={200} src={youtubelogo} />
        </div>
        <div className="text-xl bg-rose-500 text-white w-[350px] mx-auto py-2 shadow-lg shadow-rose-500/50 rounded-lg -mt-5 mb-5 text-center font-mono ">
          Montant :{" "}
          {parseFloat(data.payment_amount * data.commission).toLocaleString(
            "fr-FR",
            {
              style: "currency",
              currency: "EUR",
            }
          )}
        </div>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-base font-medium text-gray-900">Date </dt>
            <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {moment(data.payment_date).format("DD/MM/YYYY")}
            </dd>
          </div>

          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-base font-medium text-gray-900">Compte</dt>
            <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.client.name}
            </dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-base font-medium text-gray-900">Status</dt>
            <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.status ? (
                <span className="text-sky-500 font-bold uppercase"> Payé</span>
              ) : (
                <span className="text-rose-500 font-bold uppercase">
                  {" "}
                  Non Payé
                </span>
              )}
            </dd>
          </div>
          <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-base font-medium text-gray-900">Notes</dt>
            <dd className="mt-1 text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {data.note}
            </dd>
          </div>
          {/* <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-base font-medium leading-6 text-gray-900">
              Attachments
            </dt>
            <dd className="mt-2 text-base text-gray-900 sm:col-span-2 sm:mt-0">
              <ul
                role="list"
                className="divide-y divide-gray-100 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <Paperclip
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        resume_back_end_developer.pdf
                      </span>
                      <span className="flex-shrink-0 text-gray-400">2.4mb</span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href="#"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Download
                    </a>
                  </div>
                </li>
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <Paperclip
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        coverletter_back_end_developer.pdf
                      </span>
                      <span className="flex-shrink-0 text-gray-400">4.5mb</span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href="#"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Download
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div> */}
        </dl>
      </div>
      <div className="flex justify-center border-t">
        <Form
          layout="vertical"
          form={form}
          initialValues={{}}
          onFinish={updateStatus}
        >
          <Row
            justify="space-between"
            gutter={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form.Item
              label=""
              name="status"
              valuePropName="checked"
              className="mx-auto ml-12 mt-3"
            >
              <Switch
                checkedChildren="Payé"
                unCheckedChildren="Non Payé"
                defaultChecked
              />
            </Form.Item>
            <Form.Item className="mt-2">
              <Button
                htmlType="submit"
                type="primary"
                shape="circle"
                size="large"
                icon={<SaveOutlined />}
              />
            </Form.Item>
          </Row>

          <Button
            onClick={() => {
              form.resetFields();
              setOpenView(false);
            }}
            className="mx-auto rounded-lg mt-3 bg-red-500 hover:bg-red-700 hover:text-white  text-white font-bold py-2 px-4 rounded"
            style={{ width: 200, height: 50, marginBottom: 10 }}
          >
            Fermer
          </Button>
        </Form>
      </div>
    </div>
  );
}
