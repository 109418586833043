import React from "react";
import Navbar from "../components/layouts/Navbar";
import Footer from "../components/layouts/Footer";
import Home from "../pages/static/Home";
import About from "../pages/static/About";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "../pages/static/LoginPage";
import ForgotPassword from "../pages/static/forgotPassword";
import ResetPassword from "../pages/static/ResetPassword";

const ClientRoute = () => {
  return (
    <div>
      <Router>
        <Navbar />
        <Switch>
          <Route component={LoginPage} path="/login" />
          <Route component={About} path="/about" />
          <Route exact component={Home} path="/" />
          <Route component={LoginPage} path="*" />
          <Route component={ForgotPassword} path="/forgot-password" />
          <Route component={ResetPassword} path="/reset-password/:token" />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
};

export default ClientRoute;
