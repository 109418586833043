export function groupByFacebookIdAndSumRemittance(data) {
  return data.reduce((acc, item) => {
    // Initialize the group if it doesn't exist
    if (!acc[item.facebook_Id]) {
      acc[item.facebook_Id] = {
        facebook_name: item.facebook_name,
        totalRemittance: 0,
        facebook_Id: item.facebook_Id,
        product: item.product,
      };
    }

    // Add the remittance to the total for this group
    acc[item.facebook_Id].totalRemittance += item.remittance;

    return acc;
  }, {});
}
