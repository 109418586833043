import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from "antd";

export default function YoutubeForm({ form, addYoutube, dataClient }) {
  return (
    <div>
      <Form name="basic" form={form} onFinish={addYoutube} layout="vertical">
        <Row justify="space-between" gutter={12}>
          <Col xs={24} lg={12}>
            <Form.Item label="Payment Number" name="payment_number">
              <Input className="rounded-lg" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Date de Paiement"
              name="payment_date"
              rules={[
                { required: true, message: "Please select your payment date!" },
              ]}
            >
              <DatePicker style={{ width: "100%" }} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Créateur" name="client">
              <Select style={{ width: "100%" }} size="large">
                {dataClient.map((client) => (
                  <Select.Option key={client._id} value={client._id}>
                    {client.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Montant sur Youtube"
              name="payment_amount"
              rules={[
                {
                  required: true,
                  message: "Please input your payment amount!",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                className="rounded-lg"
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Devise"
              name="payment_currency"
              rules={[
                {
                  required: true,
                  message: "Please select your payment currency!",
                },
              ]}
            >
              <Select style={{ width: "100%" }} size="large">
                <Select.Option value="EUR">EUR</Select.Option>
                <Select.Option value="USD">USD</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Rate"
              name="rate"
              //   rules={[{ required: true, message: "Please input the rate!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                className="rounded-lg"
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label="Commission"
              name="commission"
              //   rules={[
              //     { required: true, message: "Please input the commission!" },
              //   ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                className="rounded-lg"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="Status" name="status" valuePropName="checked">
              <Switch
                checkedChildren="Payé"
                unCheckedChildren="Non Payé"
                defaultChecked
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label="Note" name="note">
              <Input.TextArea
                className="rounded-lg"
                placeholder="Ecrire des notes si besin ici ... Ex.: le paiement a été effectué en espèce ou Avance de paiement de 50% ... etc"
              />
            </Form.Item>
          </Col>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Envoyer
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
