import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/outline";
import Loader from "../../components/shared/Loader";

import { Link } from "react-router-dom";
import { addPave } from "../../api/paveApi";

export default function AddPave() {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState([]);
  const [images, setImages] = useState([1]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [tailles, setTailles] = useState([
    "Multi-format",
    "20x40",
    "10x15",
    "15x15",
    "15x20",
    "20x20",
    "25x20",
    "30x20",
  ]);
  const [couleurs, setCouleurs] = useState([
    "Blanc Mix",
    "Gris Anthracite",
    "Désert",
    "Terre cuite",
    "Sous Bois",
    "Lavé",
  ]);
  const [values, setValues] = useState({
    prixVente: 25,
    prixAchat: 15,
    description: "",
    taille: "",
    epaiss: "",
    gamme: "",
    tarifPose: 20,
    livraison: 5,
    couleur: "",
  });
  const [epaiss, setEpais] = useState([6, 7, 8]);
  const handleChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const addTaille = (e) => {
    e.preventDefault();
    setTailles([...tailles, values.taille]);
    setValues({ ...values, ["taille"]: " " });
  };
  const updateField = (item, value) => {
    let t = image.hasOwnProperty(item);
    setImage({
      ...image,
      [item]: value,
    });
    if (!t) {
      setImages([...images, item]);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const arrImages = Object.keys(image).map((file) => ({
      name: image[file],
    }));
    if (
      arrImages.length !== 0 &&
      values.prixVente !== "" &&
      values.prixAchat !== 0 &&
      values.description !== "" &&
      values.gamme !== "" &&
      values.livraison !== "" &&
      values.tarifPose !== ""
    ) {
      setLoading(true);
      let formData = new FormData();
      formData.append("description", values.description);
      formData.append("nomPave", values.nomPave);
      formData.append("prixVente", values.prixVente);
      formData.append("prixAchat", values.prixAchat);
      formData.append("tarifPose", values.tarifPose);
      formData.append("gamme", values.gamme);
      formData.append("livraison", values.livraison);
      arrImages.forEach((el) => formData.append("files", el.name));
      tailles.forEach((el) => formData.append("tailles", el));
      epaiss.forEach((el) => formData.append("epaisseurs", el));
      couleurs.forEach((el) => formData.append("couleurs", el));
      await addPave(formData);
      setLoading(false);
      setOpen(true);
    } else {
      setOpen1(true);
    }
  };
  const addEpais = (e) => {
    e.preventDefault();
    setEpais([...epaiss, values.epaiss]);
    setValues({ ...values, ["epaiss"]: " " });
  };
  const addCouleur = (e) => {
    e.preventDefault();
    setCouleurs([...couleurs, values.couleur]);
    setValues({ ...values, ["couleur"]: " " });
  };
  return (
    <>
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <h1 className="mb-5 text-2xl font-semibold text-gray-900">
                Ajout d'un Pavé
              </h1>
              <p className="italic">
                {" "}
                Veuillez saisir toute les informations de votre produit. une
                fois valider le pavé sera disponible pour le client
              </p>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-2 md:gap-6">
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <form action="#" method="POST">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="nomPave"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Nom du pavé
                          </label>
                          <input
                            type="text"
                            name="nomPave"
                            id="nomPave"
                            autoComplete="given-name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="gamme"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Gamme
                          </label>
                          <input
                            type="text"
                            name="gamme"
                            id="gamme"
                            autoComplete="family-name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={values.gamme}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="prixAchat"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Prix d'achat
                          </label>
                          <input
                            type="number"
                            name="prixAchat"
                            id="prixAchat"
                            autoComplete="given-name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={values.prixAchat}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="prixVente"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Prix de vente
                          </label>
                          <input
                            type="number"
                            name="prixVente"
                            id="prixVente"
                            autoComplete="family-name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={values.prixVente}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="tarifPose"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Tarif Pose
                          </label>
                          <input
                            type="number"
                            name="tarifPose"
                            id="tarifPose"
                            autoComplete="given-name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={values.tarifPose}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="livraison"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Livraison en semaine
                          </label>
                          <input
                            type="number"
                            name="livraison"
                            id="livraison"
                            autoComplete="family-name"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            value={values.livraison}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-span-6">
                          <label
                            htmlFor="description"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Description
                          </label>
                          <textarea
                            id="description"
                            name="description"
                            rows={3}
                            className="max-w-full shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                            value={values.description}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="mt-5">
                        {/* Taille disponible */}
                        <div className="grid lg:grid-cols-4 grid-cols-1">
                          <div className="col-span-2 lg:col-span-1">
                            <label
                              htmlFor="taille"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Taille disponible
                            </label>
                            <div className="">
                              <div className="flex justify-start">
                                <input
                                  type="text"
                                  name="taille"
                                  id="taille"
                                  autoComplete="family-name"
                                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  value={values.taille}
                                  onChange={handleChange}
                                />
                                <button
                                  onClick={addTaille}
                                  className="ml-3 inline-flex justify-center py-2 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  Ajout
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="lg:col-span-3 col-span-4 lg:mt-5">
                            <div className="flex flex-row justify-start flex-wrap ">
                              {tailles.map((el, i) => (
                                <button
                                  key={i}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const index = tailles.findIndex(
                                      (id) => id === el
                                    );
                                    if (index !== -1) {
                                      setTailles([
                                        ...tailles.slice(0, index),
                                        ...tailles.slice(index + 1),
                                      ]);
                                    }
                                  }}
                                  className="text-black ml-2 mt-2 inline-flex justify-center py-0.5 px-1 border border-yellow-700 shadow-sm text-sm font-medium rounded-md  hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-200 "
                                >
                                  {el}
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>

                        {/* Epaisseur Disponible */}
                        <div className="grid grid-cols-4 mt-5">
                          <div className="col-span-4 lg:col-span-1">
                            <label
                              htmlFor="epaiss"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Epaisseur disponible
                            </label>
                            <div className="">
                              <div className="flex justify-start">
                                <input
                                  type="text"
                                  name="epaiss"
                                  id="epaiss"
                                  autoComplete="family-name"
                                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  value={values.epaiss}
                                  onChange={handleChange}
                                />
                                <button
                                  onClick={addEpais}
                                  className="ml-3 inline-flex justify-center py-2 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  Ajout
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="lg:col-span-3 col-span-4">
                            <div className="flex flex-row justify-start flex-wrap ">
                              {epaiss.map((el, i) => (
                                <button
                                  key={i}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const index = epaiss.findIndex(
                                      (id) => id === el
                                    );
                                    if (index !== -1) {
                                      setEpais([
                                        ...epaiss.slice(0, index),
                                        ...epaiss.slice(index + 1),
                                      ]);
                                    }
                                  }}
                                  className="text-black ml-3 mt-5 inline-flex justify-center py-1 px-4 border border-yellow-700 shadow-sm text-sm font-medium rounded-md   hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-200"
                                >
                                  {el}
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>

                        {/* Color Disponible */}
                        <div className="grid grid-cols-4 mt-5">
                          <div className="col-span-4 lg:col-span-1">
                            <label
                              htmlFor="couleur"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Couleur disponible
                            </label>
                            <div className="">
                              <div className="flex justify-start">
                                <input
                                  type="text"
                                  name="couleur"
                                  id="couleur"
                                  autoComplete="family-name"
                                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                  value={values.couleur}
                                  onChange={handleChange}
                                />
                                <button
                                  onClick={addCouleur}
                                  className="ml-3 inline-flex justify-center py-2 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  Ajout
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="lg:col-span-3 col-span-4 lg:mt-5">
                            <div className="flex flex-row justify-start flex-wrap ">
                              {couleurs.map((el, i) => (
                                <button
                                  key={i}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const index = couleurs.findIndex(
                                      (id) => id === el
                                    );
                                    if (index !== -1) {
                                      setCouleurs([
                                        ...couleurs.slice(0, index),
                                        ...couleurs.slice(index + 1),
                                      ]);
                                    }
                                  }}
                                  className="text-black ml-3 mt-2 inline-flex justify-center py-1 px-1 border border-yellow-700 shadow-sm text-sm font-medium rounded-md  hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-200"
                                >
                                  {el}
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-1 pt-5 flex flex-wrap mt-1 sm:mt-0 sm:col-span-2">
                        {images.map((img, i) => (
                          <label
                            key={i}
                            style={{ width: 150, height: 110 }}
                            className="m-2 items-center justify-center  border-2 mb-2 border-dashed border-current hover:bg-gray-100 hover:border-current"
                          >
                            <div className="flex flex-col items-center justify-center ">
                              <div
                                className=" items-center justify-center "
                                style={{
                                  width: "99%",
                                  height: 100,
                                  textAlign: "center",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  style={{
                                    alignItems: "center",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  className=" flex items-center justify-center w-full h-full"
                                  src={
                                    Object.values(image).length === i
                                      ? null
                                      : URL.createObjectURL(image[i])
                                  }
                                  alt={file}
                                />
                              </div>
                            </div>
                            <input
                              type="file"
                              className="opacity-0"
                              accept="image/*"
                              onChange={(e) => {
                                if (e.target.files.length > 0) {
                                  updateField(i, e.target.files[0]);
                                  setFile(e.target.files[0]);
                                }
                              }}
                            />
                          </label>
                        ))}
                      </div>
                      <div className="pt-5">
                        <div className="flex justify-start">
                          <Link
                            to="/dashboard/paves"
                            className="bg-red-500 py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            Annuler
                          </Link>
                          <button
                            onClick={onSubmit}
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          >
                            Enregistrer
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /End replace */}
            </div>
          </div>
        )}
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Succès
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Materiel crée avec succès
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Link
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    to="/dashboard/paves"
                  >
                    ok
                  </Link>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open1} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen1}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Erreur !
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        🤷‍♂️ Tous les champs doivent être remplis
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen1(false)}
                  >
                    J'ai compris
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
