import { Link } from "react-router-dom";
import bandeauLogo from "../../assets/logos/bandeau_white.png";

export default function Landing() {
  return (
    <div className="relative text-white">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-2 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <div className="flex items-center justify-center gap-10">
              <img className="w-96" src={bandeauLogo} alt="Your Company" />
              {/* <h1 className="text-5xl font-extralight  "> Meta Management</h1> */}
            </div>
            <div className="hidden mx-auto sm:mt-32 sm:flex lg:mt-16">
              <div className="relative mx-auto rounded-full px-3 py-1 text-sm leading-6 text-slate-300 ring-1 ring-rose-400 hover:ring-rose-500">
                Anim aute id magna aliqua ad ad non deserunt sunt.{" "}
                <Link
                  to="#"
                  className="whitespace-nowrap font-semibold text-slate-100"
                >
                  <span className="absolute inset-0" aria-hidden="true" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>
            <h1 className="mt-24 text-5xl text-center font-bold tracking-tight text-slate-100 sm:mt-10 ">
              Monétisation
            </h1>
            <p className="mt-6 text-lg text-center leading-5 text-slate-100">
              Gére tes paiements en 1 seul click
            </p>
            <p className="mt-2 text-lg text-center leading-8 text-slate-100">
              🚀 Licence disponible sur demande
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <Link
                to="#"
                className="rounded-md bg-yellow-400 px-3.5 py-2.5 text-sm font-semibold text-slate-800 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get started
              </Link>
              <Link
                to="#"
                className="text-sm font-semibold leading-6 text-slate-100"
              >
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full rounded-xl"
            src="https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
