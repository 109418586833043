import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import moment from "moment";
import axios from "axios";
import {
  addBill,
  addBillMonth,
  deleteBillApi,
  updateBillApi,
} from "../api/billApi";
import { getAllClient } from "../api/clientApi";
import ModalAddPaiement from "./modals/ModalAddPaiement";
import ModalDeleteBill from "./modals/ModalDeleteBill";

export default function TableBills({ bills, getBills, setBills }) {
  const [detailbill, setDetailBill] = useState([]);
  const [conversion, setConversion] = useState("");
  const [billForm, setBillForm] = useState({
    payout_reference: "",
    payment_number: null,
    payout_period_start: "",
    payout_period_end: "",
    facebook_name: "",
    facebook_Id: "",
    notes: "",
    statut: false,
    product: "",
    remittance: null,
    conversion: 0.9,
    payment_currency: "USD",
    payment_date: "",
    pourcentage: 0.7,
  });
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPaiement, setOpenPaiement] = useState(false);
  const [clients, setClients] = useState([]);
  const [facebookName, setFacebookName] = useState();
  const [activePay, setActivePay] = useState();

  const [mode, setMode] = useState("create");

  let history = useHistory();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // const handleChangeName = (e) => {
  //   e.preventDefault();
  //   setFacebookName(e.target.value);
  // };

  function handleChangeName(event) {
    // Récupérer la valeur sélectionnée
    const selectedValue = event.target.value;
    // Analyser la valeur JSON pour obtenir un objet
    const selectedClient = JSON.parse(selectedValue);

    setFacebookName(selectedClient);
    // Utiliser l'objet `selectedClient` qui contient le `name` et le `_id`
    console.log(selectedClient);
  }

  const handleChange = (e) => {
    e.preventDefault();
    setBillForm({ ...billForm, [e.target.name]: e.target.value });
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const uploadResponse = await axios.post(
        "https://exportpdf.up.railway.app/send",
        formData,
        {
          withCredentials: true,
        }
      );
      console.log("Upload successful!", uploadResponse.data);
      uploadResponse.data.conversion = conversion;

      if (uploadResponse.data && conversion !== "") {
        const billResponse = await addBillMonth(uploadResponse.data);
        console.log("res", billResponse);
        setBills(billResponse.data.data);
        getBills(); // Ensure this is necessary
      }
    } catch (error) {
      console.error("Error during file upload or bill update:", error);
    }
  };

  const getClientsData = async () => {
    await getAllClient().then((res) => {
      setClients(res.data.clients);
      console.log("res.data.clients", res.data.clients);
    });
  };

  const client = () => {
    const _listUser = [];
    const _list = bills?.filter((item, index, self) => {
      return (
        index ===
        self.findIndex(
          (t) =>
            t.facebook_Id === item.facebook_Id &&
            t.facebook_name === item.facebook_name
        )
      );
    });
    _list.map((item) =>
      _listUser.push({
        facebook_Id: item.facebook_Id,
        facebook_name: item.facebook_name,
        name: item.facebook_name,
        pourcentage: 0.7,
        email: `${item.facebook_name.split(" ").join("")}@facebook.com`,
      })
    );

    console.log("_list", _list);
    console.log("_listUser", _listUser);
  };

  useEffect(() => {
    getClientsData();
  }, []);

  client();

  const addUnuiqueBill = async () => {
    billForm.payout_period_start = moment(billForm.payout_period_start).format(
      "DD-MMM-YYYY"
    );
    billForm.payout_period_end = moment(billForm.payout_period_end).format(
      "DD-MMM-YYYY"
    );
    billForm.payment_date = moment(billForm.payment_date).format("DD-MMM-YYYY");
    billForm.facebook_name = facebookName.name;
    billForm.facebook_Id = facebookName.id;

    await addBill(billForm)
      .then((res) => {
        getBills();
      })
      .then(() => {
        setOpen(false);
        setOpenUpdate(false);
      });
  };

  const handleDeleteBill = async (id) => {
    deleteBillApi(id).then((res) => {
      console.log("res.data :>> ", res.data);
      console.log(`id : ${id} is Deleted `);
      getBills();
    });
    setOpenDelete(false);
  };

  const handleUpdateBill = async (id) => {
    if (!!id)
      updateBillApi(id, billForm).then((res) => {
        console.log("res.data :>> ", res.data);
        console.log(`id : ${id} is Deleted `);
        getBills();
      });
    setOpen(false);
    setOpenUpdate(false);
  };

  const activePaid = async (data) => {
    if (!!data._id)
      updateBillApi(data._id, activePay).then((res) => {
        getBills();
      });
    setOpen(false);
    setOpenUpdate(false);
  };

  return (
    <div className="bg-gray-900 mt-10 rounded-2xl">
      <ModalAddPaiement
        billForm={billForm}
        setBillForm={setBillForm}
        setOpen={setOpen}
        open={open}
        addUnuiqueBill={addUnuiqueBill}
        handleChange={handleChange}
        clients={clients}
        handleChangeName={handleChangeName}
        facebookName={facebookName}
        mode={mode}
      />
      <ModalAddPaiement
        billForm={billForm}
        setBillForm={setBillForm}
        setOpen={setOpenUpdate}
        open={openUpdate}
        addUnuiqueBill={addUnuiqueBill}
        handleChange={handleChange}
        clients={clients}
        handleChangeName={handleChangeName}
        facebookName={facebookName}
        handleUpdateBill={handleUpdateBill}
      />
      <ModalDeleteBill
        open={openDelete}
        setOpen={setOpenDelete}
        detailBill={detailbill}
        handleDeleteBill={handleDeleteBill}
      />
      <div className="mx-auto max-w-7xl">
        <div className="bg-gray-900 py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-white">
                  Listes des Paiements reçus
                </h1>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                  onClick={() => {
                    setOpen(true);
                    setMode("create");
                  }}
                  className="block rounded-md bg-emerald-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500"
                >
                  + Ajouter un Paiment
                </button>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-700">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                        >
                          Date de Paiement
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                        >
                          Name
                        </th>
                        {/* <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                        >
                          Produit
                        </th> */}
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                        >
                          %
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                        >
                          Reçu
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-center text-sm font-semibold text-white"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">Voir</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-800">
                      {bills
                        ?.sort(
                          (a, b) =>
                            a.payout_period_start - b.payout_period_start
                        )
                        .map((pay, index) => (
                          <tr key={index}>
                            <Link to={`/dashboard/payout/${pay._id}`}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                {moment(pay.payout_period_start).format(
                                  "MMMM-YYYY"
                                )}
                              </td>
                            </Link>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {pay.facebook_name}
                            </td>
                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300 truncate w-56">
                            <p className="truncate w-36"> {pay.product}</p>
                          </td> */}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              70%
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {(
                                pay?.remittance *
                                pay?.conversion *
                                pay?.pourcentage
                              ).toFixed(2)}{" "}
                              €
                            </td>
                            <td
                              className={`whitespace-nowrap px-3 py-4 text-sm text-center uppercase ${
                                pay.statut ? "bg-emerald-400" : "bg-red-400"
                              }`}
                            >
                              {pay.statut ? "payé" : "Non Payé"}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                              <div className="flex justify-center items-center gap-5">
                                {/* <button
                                  onClick={() => {
                                    // setBillForm({
                                    //   ...pay,
                                    //   statut: !pay.statut,
                                    // });
                                    setActivePay({
                                      ...pay,
                                      statut: !pay.statut,
                                    });
                                    // console.log("billForm :>> ", billForm);
                                    activePaid(activePay);
                                  }}
                                  className="text-orange-400 hover:text-indigo-300"
                                >
                                  Paid
                                  <span className="sr-only">, {pay.name}</span>
                                </button> */}
                                <button
                                  onClick={() => {
                                    setOpenUpdate(true);
                                    setMode("update");
                                    setBillForm(pay);
                                  }}
                                  className="text-orange-400 hover:text-indigo-300"
                                >
                                  Modifier
                                  <span className="sr-only">, {pay.name}</span>
                                </button>
                                <button
                                  onClick={() => {
                                    setOpenDelete(true);
                                    setDetailBill(pay);
                                  }}
                                  className="text-red-400 hover:text-indigo-300"
                                >
                                  Delete
                                  <span className="sr-only">, {pay.name}</span>
                                </button>
                                <button
                                  onClick={() => {
                                    setDetailBill(pay);
                                    setOpenPaiement(true);
                                    history.push(`/dashboard/bills/${pay._id}`);
                                  }}
                                  className="text-sky-400 hover:text-indigo-300"
                                >
                                  Voir
                                  <span className="sr-only">, {pay.name}</span>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
