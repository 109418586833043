import { Fragment, useRef } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import meta from "../../assets/logos/meta_business.png";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ModalDeleteBill({
  detailBill,
  open,
  setOpen,
  handleDeleteBill,
}) {
  const cancelButtonRef = useRef(null);

  const statuses = {
    true: "bg-green-100 text-green-800 text-lg font-bold  ",
    false: "bg-red-100 text-red-800 text-lg font-bold",
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-10 sm:w-full sm:max-w-lg sm:p-2">
                <div>
                  <div className="flex justify-center">
                    <img
                      src={meta}
                      alt={detailBill?.facebook_name}
                      width={300}
                      className="flex-none rounded-lg bg-white object-cover z-0 -mt-5 "
                    />
                  </div>

                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {detailBill?.facebook_name}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div
                        key={detailBill?.remittance}
                        className="overflow-hidden rounded-xl "
                      >
                        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-3">
                          <Menu as="div" className="relative ml-auto">
                            <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Open options</span>
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            ></Transition>
                          </Menu>
                        </div>
                        Etes-vous sur de supprimer le paiement de{" "}
                        {detailBill?.facebook_name} ?
                      </div>
                      <div className="mt-2 text-lg font-mono">
                        {detailBill?.remittance}$
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <div>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-gray-100 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-600 sm:col-start-1 sm:mt-0"
                      onClick={() => {
                        handleDeleteBill(detailBill._id);
                      }}
                      ref={cancelButtonRef}
                    >
                      Delete
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
