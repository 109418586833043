import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  EyeIcon,
  TrashIcon,
  PencilAltIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import Loader from "../../components/shared/Loader";
import {
  addClient,
  deleteClient,
  getAllClient,
  updateClient,
} from "../../api/clientApi";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { EditFilled, DeleteOutlined, EyeFilled } from "@ant-design/icons";

export default function ClientPage() {
  const [clients, setClients] = useState([]);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [filterName, setfilterName] = useState([]);
  const [form, setForm] = useState({
    facebook_Id: "",
    facebook_name: "",
    youtube_name: "",
    name: "",
    pourcentage: null,
    pourcentage_youtube: null,
    email: "",
    vpn_name: "",
    vpn_account: "",
    status: true,
    method_paiment: "",
    comments: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const data = await getAllClient();
    console.log(data.data.clients);
    setClients(data.data.clients);
    setLoading(false);

    const _filter = data.data.clients.map((item) => {
      return {
        text: item.name,
        value: item.name,
      };
    });
    setfilterName(_filter);
  };

  function updateField(item, value) {
    setForm({
      ...form,
      [item]: value,
    });
  }

  console.log("form :>> ", form);

  const onSubmit = async () => {
    setLoading(true);
    console.log("form :>> ", form);
    if (form._id) {
      await updateClient(form._id, form).then((res) => {
        console.log("res :>> ", res);
        setOpen(false);
      });
    } else {
      await addClient(form).then((res) => {
        setOpen(false);
      });
    }
    getData();
    setMessage("Client Modifié avec succès");
    // setOpen1(true);
    setForm({
      facebook_Id: "",
      facebook_name: "",
      name: "",
      pourcentage: null,
      email: "",
      vpn_name: "",
      vpn_account: "",
      status: "",
      method_paiment: "",
      comments: "",
    });
  };

  const onDelete = async () => {
    setOpen2(false);
    setLoading(true);
    await deleteClient(id);
    setMessage("Client supprimer avec succès");
    setLoading(false);
    window.location.reload();
  };

  const columns = [
    {
      title: "Nom et Email",
      dataIndex: "name",
      key: "name",
      filters: filterName,
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      render: (text, record) => (
        <>
          <div>{record.name}</div>
          <div>{record.email}</div>
        </>
      ),
    },
    {
      title: "Facebook Id",
      dataIndex: "facebook_Id",
      key: "facebook_Id",
    },
    {
      title: "%",
      dataIndex: "pourcentage",
      key: "pourcentage",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-2">
          <button
            onClick={() => {
              setId(record._id);
              setOpen2(true);
            }}
          >
            <DeleteOutlined className="text-lg text-red-500" />
          </button>
          <button
            onClick={() => {
              setForm(record);
              setOpen(true);
            }}
          >
            <EditFilled className="text-lg text-blue-500" />
          </button>
          <Link to={`/dashboard/client/${record.facebook_Id}`}>
            <EyeFilled className="text-lg text-teal-500" />
          </Link>
        </div>
      ),
    },
  ];
  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="mb-3 text-2xl font-semibold text-gray-900">
          Liste des Créateurs
        </h1>
        <button
          onClick={() => setOpen(true)}
          type="button"
          className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Ajouter un créateur
        </button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {clients.length === 0 ? (
            <h2 className="text-center mt-10 mb-8 text-lg leading-6 font-medium text-gray-900">
              Pas de Clients en cours
            </h2>
          ) : (
            <div className="flex flex-col">
              <Table
                size="small"
                columns={columns}
                dataSource={clients}
                rowKey={(record) => record._id}
                pagination={{ pageSize: 50 }}
                scroll={{ x: "max-content" }}
              />
            </div>
          )}
        </div>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900"
                    >
                      {form._id ? `🔵  ${form.name}` : "Ajouter un client"}
                    </Dialog.Title>
                    <div className="mt-5 grid grid-cols-2 gap-4">
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom complet
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="name"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.name}
                            onChange={(e) => {
                              updateField("name", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Compte Facebook
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="facebook_name"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.facebook_name}
                            onChange={(e) => {
                              updateField("facebook_name", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Compte Youtube
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="youtube_name"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.youtube_name}
                            onChange={(e) => {
                              updateField("youtube_name", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="email"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="email"
                            id="first-name"
                            value={form.email}
                            className="shadow-sm focus:ring-current focus:border-current block w-full sm:text-sm border-blue-500 rounded-md"
                            onChange={(e) => {
                              updateField("email", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          facebook Id
                        </label>
                        <div className="mt-1">
                          <input
                            id="facebook_Id"
                            name="facebook_Id"
                            type="text"
                            required
                            value={form.facebook_Id}
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              updateField("facebook_Id", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          phone
                        </label>
                        <div className="mt-1">
                          <input
                            id="phone"
                            name="phone"
                            type="number"
                            required
                            value={form.phone}
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              updateField("phone", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Pourcentage Facebook
                        </label>
                        <div className="mt-1">
                          <input
                            id="pourcentage"
                            name="pourcentage"
                            type="number"
                            required
                            value={form.pourcentage}
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              updateField("pourcentage", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Pourcentage Youtube
                        </label>
                        <div className="mt-1">
                          <input
                            id="pourcentage_youtube"
                            name="pourcentage_youtube"
                            type="number"
                            required
                            value={form.pourcentage_youtube}
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              updateField(
                                "pourcentage_youtube",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="postCode"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Method Paiment
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="method_paiment"
                            id="method_paiment"
                            value={form.method_paiment}
                            className="shadow-sm focus:ring-current focus:border-current block w-full sm:text-sm border-blue-500 rounded-md"
                            onChange={(e) => {
                              updateField("method_paiment", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="postCode"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          VPN Name
                        </label>
                        <div className="mt-1">
                          <select
                            type="text"
                            name="vpn_name"
                            id="vpn_name"
                            value={form.vpn_name}
                            className="shadow-sm focus:ring-current focus:border-current block w-full sm:text-sm border-blue-500 rounded-md"
                            onChange={(e) => {
                              updateField("vpn_name", e.target.value);
                            }}
                          >
                            <option value=""></option>
                            <option value="ExpressVPN">ExpressVPN</option>
                            <option value="NordVPN">NordVPN</option>
                            <option value="CyberGhost">CyberGhost</option>
                          </select>
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="postCode"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          VPN account
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="vpn_account"
                            id="vpn_account"
                            value={form.vpn_account}
                            className="shadow-sm focus:ring-current focus:border-current block w-full sm:text-sm border-blue-500 rounded-md"
                            onChange={(e) => {
                              updateField("vpn_account", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="">
                        <label
                          htmlFor="postCode"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Method Paiment
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="method_paiment"
                            id="method_paiment"
                            value={form.method_paiment}
                            className="shadow-sm focus:ring-current focus:border-current block w-full sm:text-sm border-blue-500 rounded-md"
                            onChange={(e) => {
                              updateField("method_paiment", e.target.value);
                            }}
                          />
                        </div>
                      </div> */}
                      <div className="col-span-2">
                        <label
                          htmlFor="postCode"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Commentaires
                        </label>
                        <div className="mt-1">
                          <textarea
                            type="text"
                            name="comments"
                            id="comments"
                            rows={4}
                            value={form.comments}
                            className="shadow-sm focus:ring-current focus:border-current block w-full sm:text-sm border-blue-500 rounded-md"
                            onChange={(e) => {
                              updateField("comments", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center h-5">
                          <input
                            onChange={(e) => {
                              updateField("status", e.target.checked);
                            }}
                            id="comments"
                            name="comments"
                            type="checkbox"
                            checked={form.status}
                            className="mt-9 focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-8 text-sm">
                          <label
                            htmlFor="comments"
                            className=" ext-left  font-medium text-gray-700"
                          >
                            {form.status ? "Actif" : "Inactif"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 w-full border-t border-gray-400"></div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm"
                    onClick={() => onSubmit()}
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-orange-300 text-base font-medium text-gray-700 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open1} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen1}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Succès
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{message}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 sm:text-sm"
                    onClick={() => {
                      setOpen1(false);
                      window.location.reload();
                    }}
                  >
                    ok
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open2} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen2}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Suppression
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {" "}
                        Voulez-vous supprimer cet utilisateur ?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setOpen2(false);
                      onDelete();
                    }}
                  >
                    Supprimer
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen2(false)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open3} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen3}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900"
                    >
                      Informations Clients
                    </Dialog.Title>
                    <div className="mt-5 grid grid-cols-2 gap-4">
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom complet
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="name"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.name}
                            onChange={(e) => {
                              updateField("name", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="email"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="email"
                            id="first-name"
                            value={form.email}
                            className="shadow-sm focus:ring-current focus:border-current block w-full sm:text-sm border-blue-500 rounded-md"
                            onChange={(e) => {
                              updateField("email", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Ville
                        </label>
                        <div className="mt-1">
                          <input
                            id="city"
                            name="city"
                            type="text"
                            required
                            value={form.city}
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              updateField("city", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Adresse
                        </label>
                        <div className="mt-1">
                          <input
                            id="adresse"
                            name="adresse"
                            type="text"
                            required
                            value={form.adresse}
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              updateField("adresse", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Téléphone
                        </label>
                        <div className="mt-1">
                          <input
                            id="tel"
                            name="tel"
                            type="tel"
                            maxLength={10}
                            required
                            value={form.tel}
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            onChange={(e) => {
                              updateField("tel", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="postCode"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Code postal
                        </label>
                        <div className="mt-1">
                          <input
                            type="number"
                            name="postCode"
                            id="postCode"
                            maxLength={5}
                            value={form.postCode}
                            className="shadow-sm focus:ring-current focus:border-current block w-full sm:text-sm border-blue-500 rounded-md"
                            onChange={(e) => {
                              updateField("postCode", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center h-5">
                          <input
                            onChange={(e) => {
                              updateField("isEntreprise", e.target.checked);
                            }}
                            id="comments"
                            name="comments"
                            type="checkbox"
                            value={form.isEntreprise}
                            className="mt-9 focus:ring-slate-500 h-4 w-4 text-slate-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-8 text-sm">
                          <label
                            htmlFor="comments"
                            className=" ext-left  font-medium text-gray-700"
                          >
                            Une entreprise ?
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 w-full border-t border-gray-400 mb-2"></div>
                    <p className="block text-slate-700">Chef de projet </p>
                    <div className="mt-5 grid grid-cols-2 gap-4">
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom chef de projet
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="nomChef"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.nomChef}
                            onChange={(e) => {
                              updateField("nomChef", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="emailChef"
                            type="email"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.emailChef}
                            onChange={(e) => {
                              updateField("emailChef", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Téléphone
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="telChef"
                            type="number"
                            maxLength={10}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.telChef}
                            onChange={(e) => {
                              updateField("telChef", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Poste
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="posteChef"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.posteChef}
                            onChange={(e) => {
                              updateField("posteChef", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 w-full border-t border-gray-400 mb-2"></div>
                    <p className="block text-slate-700">Comptable</p>
                    <div className="mt-5 grid grid-cols-2 gap-4">
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom complet
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="nomCompta"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.nomCompta}
                            onChange={(e) => {
                              updateField("nomCompta", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="emailCompta"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.emailCompta}
                            onChange={(e) => {
                              updateField("emailCompta", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Téléphone
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="telCompta"
                            type="number"
                            maxLength={10}
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.telCompta}
                            onChange={(e) => {
                              updateField("telCompta", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Poste(extention)
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="posteCompta"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-blue-500 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            value={form.posteCompta}
                            onChange={(e) => {
                              updateField("posteCompta", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-700 sm:col-start-2 sm:text-sm"
                    // onClick={() => onSubmit()}
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-red-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen3(false)}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
