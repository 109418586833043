import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Badge, Table, Button, Tag, Row } from "antd";
import { deletePaiement, updateTabinBill } from "../../api/paiementApi";
import ModalAddPaiement from "../modals/ModalAddPaiement";
import { addBill, deleteBillApi } from "../../api/billApi";
import moment from "moment";
import ModalResumePayment from "../modals/ModalResumePayment";
import { calculateTotalRemittance } from "../../utils/calculateTotalRemittance";
import "./style.css";

const TablePayout = ({ getBills, payout, loading }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [billForm, setBillForm] = useState({
    payout_reference: "",
    payment_number: null,
    payout_period_start: "",
    payout_period_end: "",
    facebook_name: "",
    facebook_Id: "",
    notes: "",
    status: false,
    product: "",
    remittance: null,
    conversion: 0.9,
    payment_currency: "USD",
    payment_date: "",
    pourcentage: 0.7,
  });
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [clients, setClients] = useState([]);
  const [facebookName, setFacebookName] = useState();
  const [payoutID, setPayoutID] = useState();
  const [sortedData, setSortedData] = useState([]);
  const [dataPaiement, setDataPaiement] = useState({});

  const expandedRowRender = (record) => {
    setPayoutID(record._id);

    const totalRemittance = calculateTotalRemittance(record?.tab);
    const unifiedData = record?.tab.map((item) => ({
      ...item,
      total_remittance: totalRemittance[item.facebook_Id],
    }));

    const columns = [
      {
        title: "Name",
        dataIndex: "facebook_name",
        key: "facebook_name",
        filters: unifiedData.map((item) => ({
          text: item.facebook_name,
          value: item.facebook_name,
        })),
        onFilter: (value, record) =>
          record.facebook_name.toLowerCase().includes(value.toLowerCase()),
        render: (text, record) => (
          <Row>
            {/* <Link to={`/dashboard/client/${record.facebook_Id}`}>{text}</Link> */}
            <Button
              type="link"
              onClick={() => {
                setDataPaiement(record);
                setBillForm(record);
                setOpen(true);
              }}
            >
              {" "}
              {text}{" "}
            </Button>
          </Row>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        // key: "status",
        align: "center",
        filters: [
          { text: "Payé", value: true },
          { text: "Non Payé", value: false },
        ],
        onFilter: (value, record) => record.status === value,
        render: (status) =>
          status ? (
            <Tag color="green">Payé</Tag>
          ) : (
            <Tag color="red">Non Payé</Tag>
          ),
      },
      // { title: "Product", dataIndex: "product", key: "product" },
      {
        title: "Remittance",
        dataIndex: "remittance",
        // key: "remittance",
        sorter: (a, b) => parseFloat(b.remittance) - parseFloat(a.remittance),
        render: (_, record) => (
          <div
            className="text-blue-500 hover:text-blue-700 cursor-pointer"
            onClick={() => {
              setOpenUpdate(true);
              setBillForm(record);
            }}
          >
            {`${parseFloat(record.remittance).toLocaleString("fr-FR", {
              style: "currency",
              currency: "USD",
            })}`}
          </div>
        ),
      },
    ];

    return (
      <>
        <Table
          className="custom-table"
          columns={columns}
          // dataSource={record.tab}
          dataSource={unifiedData}
          pagination={false}
        />
        <ModalResumePayment
          open={open}
          handleChange={handleChange}
          billForm={billForm}
          setBillForm={setBillForm}
          setOpen={setOpen}
          data={dataPaiement}
          handlePay={handlePay}
        />
      </>
    );
  };

  const handleChange = (e) => {
    e.preventDefault();
    setBillForm({ ...billForm, [e.target.name]: e.target.value });
  };

  function handleChangeName(event) {
    // Récupérer la valeur sélectionnée
    const selectedValue = event.target.value;
    // Analyser la valeur JSON pour obtenir un objet
    const selectedClient = JSON.parse(selectedValue);
    setFacebookName(selectedClient);
  }

  const addUnuiqueBill = async () => {
    billForm.payout_period_start = moment(billForm.payout_period_start).format(
      "DD-MMM-YYYY"
    );
    billForm.payout_period_end = moment(billForm.payout_period_end).format(
      "DD-MMM-YYYY"
    );
    billForm.payment_date = moment(billForm.payment_date).format("DD-MMM-YYYY");
    billForm.facebook_name = facebookName.name;
    billForm.facebook_Id = facebookName.id;

    await addBill(billForm)
      .then((res) => {
        getBills();
      })
      .then(() => {
        setOpen(false);
        setOpenUpdate(false);
      });
  };

  const handleDeleteBill = async (id) => {
    deleteBillApi(id).then((res) => {
      console.log("res.data :>> ", res.data);
      console.log(`id : ${id} is Deleted `);
      getBills();
    });
    setOpenDelete(false);
  };

  const handleUpdateBill = async () => {
    console.log("billForm", billForm);
    if (!!billForm.remittance)
      billForm.remittance = parseFloat(billForm.remittance);
    const dataToSend = {
      custom_id: billForm.custom_id,
      update: billForm,
      id: payoutID,
    };

    if (!!billForm.custom_id)
      updateTabinBill(dataToSend).then((res) => {
        getBills();
      });
    setOpen(false);
    setOpenUpdate(false);
  };

  const handlePay = async () => {
    if (billForm.status === false) {
      billForm.status = true;
    } else {
      billForm.status = false;
    }

    const dataToSend = {
      custom_id: billForm.custom_id,
      update: billForm,
      id: payoutID,
    };

    if (!!billForm.custom_id)
      updateTabinBill(dataToSend).then((res) => {
        console.log("res.data :>> ", res.data);
        getBills();
      });
    setOpen(false);
    // setOpenUpdate(false);
  };

  const handleDelete = (id) => {
    deletePaiement(id).then((res) => {
      console.log("res :>> ", res);
      getBills();
    });
  };

  const columns = [
    {
      title: "Date de Paiement",
      dataIndex: "payment_date",
      key: "payment_date",
      sorter: (a, b) => moment(a.payment_date) - moment(b.payment_date),
      defaultSortOrder: "descend", // Ajoutez cette ligne
      render: (record) => moment(record).format("DD.MM.YYYY"),
    },
    {
      title: "Total",
      dataIndex: "payment_amount",
      key: "payment_amount",
      sorter: (a, b) => a.payment_amount - b.payment_amount,
      render: (text) =>
        `${parseFloat(text).toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        })}`,
    },

    {
      title: "Payout Reference",
      dataIndex: "payment_number",
      key: "payment_number",
    },
    {
      title: "Tx ",
      dataIndex: "conversion",
      key: "conversion",
      align: "center",
      render: (_, record) => (
        <Badge
          count={record?.tab[0]?.conversion}
          style={{ backgroundColor: "#00a9ff", minWidth: "50px" }}
          className="font-mono  "
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="danger"
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(record._id)}
        />
      ),
    },
  ];

  useEffect(() => {
    let sorted = [...payout];

    sorted.sort((a, b) => moment(b.payment_date) - moment(a.payment_date));
    setSortedData(sorted);
  }, [payout]);

  return (
    <>
      <Table
        columns={columns}
        // className="custom-table"
        size="small"
        loading={loading}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["1"],
        }}
        rowKey="_id"
        dataSource={sortedData}
        scroll={{ x: 500 }}
        pagination={{ pageSize: 15 }}
      />
      {console.log("billForm", billForm)}
      <ModalAddPaiement
        billForm={billForm}
        setBillForm={setBillForm}
        setOpen={setOpenEdit}
        open={openEdit}
        addUnuiqueBill={addUnuiqueBill}
        handleChange={handleChange}
        clients={clients}
        handleChangeName={handleChangeName}
        facebookName={facebookName}
        mode={"create"}
      />
      <ModalAddPaiement
        billForm={billForm}
        setBillForm={setBillForm}
        setOpen={setOpenUpdate}
        open={openUpdate}
        addUnuiqueBill={addUnuiqueBill}
        handleChange={handleChange}
        clients={clients}
        handleChangeName={handleChangeName}
        facebookName={facebookName}
        handleUpdateBill={handleUpdateBill}
      />
    </>
  );
};

export default TablePayout;
