import { CheckIcon } from "@heroicons/react/outline";
import meta from "../../assets/logos/meta_business.png";
import { Modal, Button, Switch } from "antd";

export default function ModalResumePayment({
  data,
  open,
  setOpen,
  handlePay,
  billForm,
  setBillForm,
}) {
  // const statuses = {
  //   true: "bg-green-100 text-green-800 text-lg font-bold  ",
  //   false: "bg-red-100 text-red-800 text-lg font-bold",
  // };

  return (
    <Modal
      visible={open}
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Fermer
        </Button>,
        // <Switch
        //   className="mt-3 items-center justify-center"
        //   checkedChildren="Payé"
        //   unCheckedChildren="Non Payé"
        //   defaultChecked={data?.status}
        //   onChange={(checked) => {
        //     if (checked) {
        //       handlePay();
        //     }
        //     setOpen(false);
        //   }}
        // />,
      ]}
    >
      <div>
        <div className="flex justify-center">
          <img
            src={meta}
            alt={data?.facebook_name}
            width={300}
            className="flex-none rounded-lg bg-white object-cover z-0 -mt-5 "
          />
        </div>
        <div className="grid grid-cols-1 ">
          <div className=" font-medium text-center leading-6 text-gray-900">
            <span className="text-xl"> {data?.facebook_name}</span> -{" "}
            <span className="font-semibold italic "> #{data?.facebook_Id}</span>
          </div>
        </div>
        <div className="text-3xl flex justify-center items-center gap-3 font-mono z-50 text-white shadow-lg shadow-blue-500/50  mx-auto text-center font-extrabold px-5 border-2 border-blue-300  bg-blue-500 rounded-lg py-2 ">
          {Math.floor(
            data?.total_remittance * data.conversion * data?.pourcentage
          ).toLocaleString("fr-FR", {
            style: "currency",
            currency: "EUR",
          })}
          {data.status && (
            <div className="bg-teal-500 shadow-teal-500/50 shadow-lg flex items-center justify-center ml-5 px-3 rounded-lg">
              <CheckIcon
                className="h-8 w-8 text-slate-100"
                aria-hidden="true"
              />
              <p className="text-sm  "> Payé </p>{" "}
            </div>
          )}
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <p className="text-sm font-light italic text-red-500 ">
            {" "}
            Les paiements arriveront sous un délai de 10 jours en fonction de
            nos systémes de paiement
          </p>
          <div className="mt-4"> Détail du paiement </div>

          <div className="mt-2">
            <div
              key={data?.remittance}
              className="overflow-hidden rounded-xl border border-slate-500"
            >
              <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-3"></div>
              <dl className="-my-3 divide-y divide-gray-100 px-6 py-2 text-sm leading-6">
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">Période </dt>
                  <dd className="text-blue-700">
                    <time dateTime={data?.payout_period_start}>
                      {data?.payout_period_start} à {data?.payout_period_end}
                    </time>
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-2">
                  <dt className="text-gray-500">Produit</dt>
                  <dd className="flex items-start gap-x-2">
                    <div className="font-medium text-blue-900">
                      {data?.product}
                    </div>
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-2">
                  <dt className="text-gray-500">Montant en $ brut</dt>
                  <dd className="flex items-start gap-x-2">
                    <div className="font-medium text-blue-900">
                      {Math.floor(data?.total_remittance)} $
                    </div>
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-2">
                  <dt className="text-gray-500">Montant en € converti</dt>
                  <dd className="flex items-start gap-x-2">
                    <div className="font-medium text-blue-900">
                      {Math.floor(data?.conversion * data?.total_remittance)} €
                    </div>
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-2">
                  <dt className="text-gray-500">Pourcentage</dt>
                  <dd className="flex items-start gap-x-2">
                    <div className="font-medium text-blue-900">
                      {data?.pourcentage * 100} %
                    </div>
                  </dd>
                </div>
                {/* <div className="flex justify-between gap-x-4 py-2">
                            <dt className="text-blue-500 font-bold">
                              A payé en Euros - €
                            </dt>
                            <dd className="flex items-start gap-x-2">
                              <div
                                className={classNames(
                                  statuses[!!data?.total_remittance],
                                  "rounded-md py-1 px-4 text-xl font-bold  text-blue-800 ring-blue-500 ring-1 ring-inset"
                                )}
                              >
                                {(
                                  data?.total_remittance *
                                  data.conversion *
                                  data?.pourcentage
                                ).toFixed(2)}{" "}
                                €
                              </div>
                            </dd>
                          </div> */}
              </dl>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2  sm:gap-3">
        <div>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => setOpen(false)}
            ref={cancelButtonRef}
          >
            Fermer
          </button>
        </div>
      </div> */}
      {/* <div className="z-50 mx-auto flex justify-center">
        <Switch
          className="mt-3 items-center justify-center"
          checkedChildren="Payé"
          unCheckedChildren="Non Payé"
          defaultChecked={data?.status}
          onChange={(checked) => {
            setBillForm({
              ...billForm,
              status: checked,
            });
            handlePay();

            // setTimeout(() => {
            //   setOpen(false);
            // }, 2000);
          }}
        />
      </div> */}

      {data.status ? (
        <div className="z-50 mx-auto flex justify-center">
          <button
            className="mt-3 items-center justify-center  bg-red-500  text-white px-3 py-2 rounded-md"
            onClick={() => {
              handlePay();
            }}
          >
            unpaied ?
          </button>
        </div>
      ) : (
        <div className="z-50 mx-auto flex justify-center">
          <button
            className="mt-3 items-center justify-center bg-green-500 text-white px-3 py-2 rounded-md"
            onClick={() => {
              handlePay();
            }}
          >
            Paid ?
          </button>
        </div>
      )}
    </Modal>
  );
}
