import { Fragment, useRef } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import meta from "../../assets/logos/meta_business.png";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ModalPaiementClient({
  detailBill,
  open,
  setOpen,
  paiementTotal,
}) {
  const cancelButtonRef = useRef(null);

  const statuses = {
    true: "bg-green-100 text-green-800 text-lg font-bold  ",
    false: "bg-red-100 text-red-800 text-lg font-bold",
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-10 sm:w-full sm:max-w-lg sm:p-2">
                <div>
                  <div className="flex justify-center">
                    <img
                      src={meta}
                      alt={detailBill?.facebook_name}
                      width={300}
                      className="flex-none rounded-lg bg-white object-cover z-0 -mt-5 "
                    />
                  </div>
                  <div className="text-3xl  z-50 text-white shadow-lg shadow-blue-500/50  mx-auto text-center font-extrabold px-5 border-2 border-blue-300  bg-blue-500 rounded-lg ">
                    {(
                      paiementTotal?.totalRemittance *
                      detailBill.conversion *
                      detailBill?.pourcentage
                    ).toFixed(2)}{" "}
                    €
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      <p className="text-sm font-light italic text-red-500 ">
                        {" "}
                        Les paiements arriveront sous un délai de 10 jours en
                        fonction de nos systémes de paiement
                      </p>
                      <div className="mt-4"> Détail du paiement </div>
                    </Dialog.Title>
                    <div className="mt-2">
                      <div
                        key={detailBill?.remittance}
                        className="overflow-hidden rounded-xl border border-slate-500"
                      >
                        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-3">
                          {/* <img
                            // src={detailBill?.imageUrl}
                            src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Meta-Logo.png"
                            alt={detailBill?.facebook_name}
                            className="h-16 w-16 flex-none rounded-lg bg-white object-fit ring-1 ring-gray-900/10"
                          /> */}
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                          <div className="grid grid-cols-1 ">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              {detailBill?.facebook_name}
                            </div>
                            <div className="text-sm  font-medium leading-6 text-gray-900 ">
                              {detailBill?.facebook_Id}
                            </div>
                          </div>
                          <Menu as="div" className="relative ml-auto">
                            <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Open options</span>
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              {/* <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to="#"
                                      className={classNames(
                                        active ? "bg-gray-50" : "",
                                        "block px-3 py-1 text-sm leading-6 text-gray-900"
                                      )}
                                    >
                                      View
                                      <span className="sr-only">
                                        {detailBill?.facebook_name}
                                      </span>
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to="#"
                                      className={classNames(
                                        active ? "bg-gray-50" : "",
                                        "block px-3 py-1 text-sm leading-6 text-gray-900"
                                      )}
                                    >
                                      Edit
                                      <span className="sr-only">
                                        {detailBill?.facebook_name}
                                      </span>
                                    </Link>
                                  )}
                                </Menu.Item>
                              </Menu.Items> */}
                            </Transition>
                          </Menu>
                        </div>
                        <dl className="-my-3 divide-y divide-gray-100 px-6 py-2 text-sm leading-6">
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Période </dt>
                            <dd className="text-blue-700">
                              <time dateTime={detailBill?.payout_period_start}>
                                {detailBill?.payout_period_start} à{" "}
                                {detailBill?.payout_period_end}
                              </time>
                            </dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-2">
                            <dt className="text-gray-500">Produit</dt>
                            <dd className="flex items-start gap-x-2">
                              <div className="font-medium text-blue-900">
                                {paiementTotal?.paiementTotal}
                              </div>
                            </dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-2">
                            <dt className="text-gray-500">Montant en $ brut</dt>
                            <dd className="flex items-start gap-x-2">
                              <div className="font-medium text-blue-900">
                                {paiementTotal?.totalRemittance.toFixed(2)} $
                              </div>
                            </dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-2">
                            <dt className="text-gray-500">
                              Montant en € converti
                            </dt>
                            <dd className="flex items-start gap-x-2">
                              <div className="font-medium text-blue-900">
                                {(
                                  detailBill?.conversion *
                                  paiementTotal?.totalRemittance
                                ).toFixed(2)}{" "}
                                €
                              </div>
                            </dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-2">
                            <dt className="text-gray-500">Pourcentage</dt>
                            <dd className="flex items-start gap-x-2">
                              <div className="font-medium text-blue-900">
                                {detailBill?.pourcentage * 100} %
                              </div>
                            </dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-2">
                            <dt className="text-blue-500 font-bold">
                              A payé en Euros - €
                            </dt>
                            <dd className="flex items-start gap-x-2">
                              <div
                                className={classNames(
                                  statuses[!!paiementTotal?.totalRemittance],
                                  "rounded-md py-1 px-4 text-xl font-bold  text-blue-800 ring-blue-500 ring-1 ring-inset"
                                )}
                              >
                                {(
                                  paiementTotal?.totalRemittance *
                                  detailBill.conversion *
                                  detailBill?.pourcentage
                                ).toFixed(2)}{" "}
                                €
                              </div>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
