import { useEffect, useState } from "react";
import { addPayout, getAllBill } from "../../api/billApi";
import TablePayout from "../../components/Tables/TablePayout";
import axios from "axios";
import TablePaiement from "../../components/TablePaiement";
import Loader from "../../components/shared/Loader";

// const stats = [
//   {
//     name: "Revenue",
//     value: "$405,091.00",
//     change: "+4.75%",
//     changeType: "positive",
//   },
//   {
//     name: "Overdue invoices",
//     value: "$12,787.00",
//     change: "+54.02%",
//     changeType: "negative",
//   },
//   {
//     name: "Outstanding invoices",
//     value: "$245,988.00",
//     change: "-1.39%",
//     changeType: "positive",
//   },
//   {
//     name: "Expenses",
//     value: "$30,156.00",
//     change: "+10.18%",
//     changeType: "negative",
//   },
// ];
// const statuses = {
//   Paid: "text-green-700 bg-green-50 ring-green-600/20",
//   Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
//   Overdue: "text-red-700 bg-red-50 ring-red-600/10",
// };

export default function PayoutPage() {
  const [bills, setBills] = useState([]);
  const [payout, setPayout] = useState([]);
  const [loading, setLoading] = useState(false);
  const [conversion, setConversion] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    setLoading(true);
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const uploadResponse = await axios.post(
        "https://exportpdf.up.railway.app/send",
        formData,
        {
          withCredentials: true,
        }
      );
      uploadResponse.data.conversion = conversion;
      if (uploadResponse.data && conversion !== "") {
        formData.append("conversion", uploadResponse.data.conversion);
        formData.append("payee", uploadResponse.data.payee);
        formData.append("payment_amount", uploadResponse.data.payment_amount);
        formData.append(
          "payment_currency",
          uploadResponse.data.payment_currency
        );
        formData.append("payment_date", uploadResponse.data.payment_date);
        formData.append("payment_number", uploadResponse.data.payment_number);
        formData.append("total", uploadResponse.data.total);

        uploadResponse.data.tab.forEach((item, index) => {
          formData.append(`tab[${index}][facebook_Id]`, item.facebook_Id);
          formData.append(`tab[${index}][facebook_name]`, item.facebook_name);
          formData.append(
            `tab[${index}][payout_period_end]`,
            item.payout_period_end
          );
          formData.append(
            `tab[${index}][payout_period_start]`,
            item.payout_period_start
          );
          formData.append(
            `tab[${index}][payout_reference]`,
            item.payout_reference
          );
          formData.append(`tab[${index}][product]`, item.product);
          formData.append(`tab[${index}][remittance]`, item.remittance);
        });

        const billResponse = await addPayout(formData).then((res) => {
          console.log("res :>> ", res.data);
          getBills();
          setLoading(false);
        });

        setBills(billResponse.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during file upload or bill update:", error);
    }
  };

  const getBills = async () => {
    setLoading(true);
    await getAllBill()
      .then((res) => {
        setBills(res.data.bills);
        setPayout(res.data.payout);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error :>> ", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getBills();
  }, []);

  return (
    <main>
      <h1 className="text-3xl font-bold text-slate-900 m-4">
        Liste des Payouts
      </h1>
      <div className="px-5">
        <div className="bg-gray-900 mt-4 p-3 rounded-2xl">
          <div className="flex justify-center px-4">
            <input
              className="bg-orange-300  rounded-md mt-5 self-center ml-5  w-3/4 "
              type="file"
              onChange={handleFileChange}
            />
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 mt-4 ">
            <div className="flex justify-center items-center px-4">
              <label className="text-white mr-5">Tx conversion :</label>
              <input
                type="number"
                name="nom"
                id="nom"
                onChange={(e) => {
                  setConversion(e.target.value);
                }}
                className="mt-1 bg-gray-100 block w-20   shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="mt-4 mx-auto sm:ml-16 sm:mt-0 sm:flex-none">
              {loading ? (
                <Loader />
              ) : (
                <button
                  disabled={conversion === ""}
                  onClick={handleUpload}
                  type="button"
                  className="block rounded-md bg-orange-500 px-8 py-2 text-center text-sm font-semibold text-white hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                >
                  Upload
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="mt-5">
          {/* <TablePaiement
            bills={bills}
            getBills={getBills}
            setBills={setBills}
            payout={payout}
            loading={loading}
          /> */}
          <TablePayout
            bills={bills}
            getBills={getBills}
            setBills={setBills}
            payout={payout}
            loading={loading}
          />
        </div>
      </div>
    </main>
  );
}
