import { EyeIcon, TrashIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import ModalPaiementClient from "../modals/ModalPaiementClient";
import { groupByFacebookIdAndSumRemittance } from "../../utils/groupPaiement";

const TablePay = ({ data }) => {
  console.log("data :>> ", data);
  const [open, setOpen] = React.useState(false);

  const [facebookIdSelected, setFacebookIdSelected] = React.useState();
  const [remitObj, setRemitObj] = React.useState();
  const [infoPaiement, setInfoPaiement] = useState({});

  useEffect(() => {
    if (!!data) {
      const dataGG = groupByFacebookIdAndSumRemittance(data);

      setRemitObj(dataGG[facebookIdSelected]);
    }
  }, [data, facebookIdSelected]);

  return (
    <div className="bg-gray-900 mt-10 rounded-2xl p-1">
      <div className="mt-2 flow-root">
        <div className="mx-1 -my-2 overflow-x-auto sm:-mx-2 lg:mx-2">
          <div className="inline-block min-w-full py-2 align-middle px-5 sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-700">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                  >
                    Paiement Avant
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="px-3 py-3.5 text-center text-sm font-semibold text-white">
                      Actions
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-800">
                {!!data &&
                  data
                    ?.sort((a, b) => b.remittance - a.remittance)
                    .map((pay) => (
                      <tr key={pay.facebook_name + pay.remittance}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                          <div className="flex">
                            <EyeIcon
                              className="text-blue-400 h-6 mr-5 cursor-pointer"
                              onClick={() => {
                                setOpen(true);
                                setFacebookIdSelected(pay.facebook_Id);
                                setInfoPaiement(pay);
                              }}
                            />{" "}
                            {pay.facebook_name}
                          </div>
                        </td>

                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                          <div className="flex">
                            <span
                              onClick={() => {
                                setOpen(true);
                                setFacebookIdSelected(pay.facebook_Id);
                                setInfoPaiement(pay);
                              }}
                            >
                              $ {pay.remittance}
                            </span>
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                          {pay.statut ? (
                            <span className="text-green-400">Payé</span>
                          ) : (
                            <span className="text-red-400"> Non Payé</span>
                          )}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <div className="grid grid-cols-1">
                            <button className=" mx-auto">
                              <TrashIcon className="text-red-500 h-6" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalPaiementClient
        open={open}
        setOpen={setOpen}
        paiementTotal={remitObj}
        detailBill={infoPaiement}
      />
    </div>
  );
};

export default TablePay;
