import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";

export default function ModalAddPaiement({
  billForm,
  setBillForm,
  open,
  setOpen,
  addUnuiqueBill,
  handleChange,
  clients,
  handleChangeName,
  facebookName,
  mode,
  handleUpdateBill,
}) {
  const cancelButtonRef = useRef(null);

  const products = [
    {
      id: "0",
      name: "In-stream Ads",
    },
    {
      id: "1",
      name: "Video Ad Activity",
    },
    {
      id: "2",
      name: "Ads on Reels ",
    },
    {
      id: "3",
      name: "-",
    },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 pos"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left w-[500px] shadow-xl transition-all sm:my-10 sm:w-full sm:max-w-xl sm:p-2">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      <div className="font-bold uppercase">
                        {" "}
                        {mode === "create"
                          ? " Nouveau Paiement"
                          : "Modification : " + billForm.facebook_name}
                      </div>{" "}
                      <div className="mt-4">
                        {" "}
                        Saisir toutes les informations
                      </div>
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="overflow-hidden rounded-xl border border-slate-500">
                        <div className=" tems-center gap-x-2 border-b border-gray-900/5 bg-gray-50 p-3">
                          <div className="grid md:grid-cols-2 ">
                            <label
                              htmlFor="nom"
                              className="text-sm self-center font-medium text-gray-700 mt-2 text-left md:text-right mr-3"
                            >
                              Payout Reference :
                            </label>
                            <input
                              type="text"
                              name="payout_reference"
                              id="payout_reference"
                              className="mt-1 h-8 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              onChange={handleChange}
                              value={billForm.payout_reference}
                            />
                          </div>
                          <div className="grid md:grid-cols-2 ">
                            <label
                              htmlFor="nom"
                              className="text-sm self-center font-medium text-gray-700 mt-2 text-left md:text-right mr-3"
                            >
                              Payment Number :
                            </label>
                            <input
                              type="number"
                              name="payment_number"
                              id="payment_number"
                              className="mt-1 h-8 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              onChange={handleChange}
                              value={billForm.payment_number}
                            />
                          </div>
                          <div className="grid md:grid-cols-2 ">
                            <label
                              htmlFor="nom"
                              className="text-sm self-center font-medium text-gray-700 mt-2 text-left md:text-right mr-3"
                            >
                              Payout Period Start :
                            </label>
                            <input
                              type="date"
                              name="payout_period_start"
                              id="payout_period_start"
                              className="mt-1 h-8 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              onChange={handleChange}
                              value={moment(
                                billForm.payout_period_start
                              ).format("YYYY-MM-DD")}
                            />
                          </div>

                          <div className="grid md:grid-cols-2 ">
                            <label
                              htmlFor="nom"
                              className="text-sm self-center font-medium text-gray-700 mt-2 text-left md:text-right mr-3"
                            >
                              Payout Period End :
                            </label>
                            <input
                              type="date"
                              name="payout_period_end"
                              id="payout_period_end"
                              className="mt-1 h-8 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              onChange={handleChange}
                              value={moment(billForm.payout_period_end).format(
                                "YYYY-MM-DD"
                              )}
                            />
                          </div>
                          <div className="grid md:grid-cols-2 ">
                            <label
                              htmlFor="nom"
                              className="text-sm self-center font-medium text-gray-700 mt-2 text-left md:text-right mr-3"
                            >
                              Payment date:
                            </label>
                            <input
                              type="date"
                              name="payment_date"
                              id="payment_date"
                              className="mt-1 h-8 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              onChange={handleChange}
                              value={moment(billForm.payment_date).format(
                                "YYYY-MM-DD"
                              )}
                            />
                          </div>

                          {mode === "create" && (
                            <>
                              <div className="grid md:grid-cols-2 ">
                                <label
                                  htmlFor="nom"
                                  className="text-sm self-center font-medium text-gray-700 mt-2 text-left md:text-right mr-3"
                                >
                                  Facebook Name :
                                </label>

                                <select
                                  onChange={handleChangeName}
                                  id="client"
                                  name="client"
                                  className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                  value={billForm.client}
                                >
                                  <option></option>
                                  {clients.map((client, i) => (
                                    <option
                                      value={JSON.stringify({
                                        name: client.name,
                                        _id: client._id,
                                      })}
                                      key={i}
                                    >
                                      {client.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </>
                          )}

                          <div className="grid md:grid-cols-2 ">
                            <label
                              htmlFor="nom"
                              className="text-sm self-center font-medium text-gray-700 mt-2 text-left md:text-right mr-3"
                            >
                              Product :
                            </label>

                            <select
                              onChange={handleChange}
                              id="product"
                              name="product"
                              autoComplete="country-name"
                              value={billForm.product}
                              className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            >
                              <option></option>
                              {products.map((item, i) => (
                                <option value={item.name} key={i}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="grid md:grid-cols-2 ">
                            <label
                              htmlFor="nom"
                              className="text-sm self-center font-medium text-gray-700 mt-2 text-left md:text-right mr-3"
                            >
                              Remittance:
                            </label>
                            <input
                              type="text"
                              name="remittance"
                              id="remittance"
                              className="mt-1 h-8 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              onChange={handleChange}
                              value={billForm.remittance}
                            />
                          </div>
                          <div className="grid md:grid-cols-2 ">
                            <label
                              htmlFor="nom"
                              className="text-sm self-center font-medium text-gray-700 mt-2 text-left md:text-right mr-3"
                            >
                              conversion:
                            </label>
                            <input
                              type="number"
                              name="conversion"
                              id="conversion"
                              className="mt-1 h-8 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              onChange={handleChange}
                              value={billForm.conversion}
                            />
                          </div>
                          <div className="grid md:grid-cols-2 ">
                            <label
                              htmlFor="nom"
                              className="text-sm self-center font-medium text-gray-700 mt-2 text-left md:text-right mr-3"
                            >
                              Pourcentage:
                            </label>
                            <input
                              type="number"
                              name="pourcentage"
                              id="pourcentage"
                              className="mt-1 h-8 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              onChange={handleChange}
                              value={billForm.pourcentage}
                            />
                          </div>
                        </div>
                        <div className="grid md:grid-cols-2 mb-5 p-3  ">
                          <label
                            htmlFor="nom"
                            className="text-sm  pl-3 self-center font-medium text-gray-700 mt-2 text-left md:text-right mr-3"
                          >
                            Notes:
                          </label>
                          <textarea
                            type="text"
                            name="notes"
                            value={billForm.notes}
                            rows={4}
                            id="notes"
                            className="mt-1  p-3 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mt-5 flex items-center justify-center">
                    <div>
                      Paiement effectué ?
                      <input
                        id="status"
                        aria-describedby="status"
                        name="status"
                        type="checkbox"
                        checked={billForm.status}
                        className="h-6 w-6 ml-10 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                        onChange={(event) => {
                          setBillForm({
                            ...billForm,
                            status: event.target.checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 md:flex sm:gap-3">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-orange-400 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-orange-500 sm:col-start-1 sm:mt-0"
                    onClick={() => {
                      setOpen(false);
                      setBillForm({
                        payout_reference: "",
                        payment_number: null,
                        payout_period_start: "",
                        payout_period_end: "",
                        facebook_name: "",
                        facebook_Id: "",
                        notes: "",
                        status: false,
                        product: "",
                        remittance: null,
                        conversion: 0.9,
                        payment_currency: "USD",
                        payment_date: "",
                        pourcentage: 0.7,
                      });
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-emerald-500  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-emerald-400 sm:col-start-1 sm:mt-0"
                    onClick={() => {
                      mode === "create"
                        ? addUnuiqueBill(billForm)
                        : handleUpdateBill(billForm._id);
                    }}
                  >
                    Valide
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
