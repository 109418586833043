import { useEffect, useState } from "react";
import {
  addYoutubeApi,
  deleteYoutubeApi,
  getAllYoutubeApi,
  updateYoutubeApi,
} from "../../api/youtubeApi";
import { getAllClient } from "../../api/clientApi";
import { Modal, Table, Form, Popconfirm, message, Image, Tag } from "antd";
import YoutubeForm from "../../components/YoutubeForm";
import moment from "moment";
import YoutubeView from "../../components/YoutubeView";
import clientYoutube from "../../datas/clientYoutube.json";
import youtubelogo from "../../assets/logo-Youtube.png";
import { Eye, FilePenLine, Trash2 } from "lucide-react";

export default function YoutubePage() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [dataYoutube, setDataYoutube] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [dataClient, setDataClient] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [idSelected, setIdSelected] = useState("");
  const [openView, setOpenView] = useState(false);
  const [openModalClient, setOpenModalClient] = useState(false);

  function generateId() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    return `YT${year}${month}`;
  }

  const columns = [
    {
      title: "Créateur",
      dataIndex: "client",
      key: "client",
      width: 200,
      filters: dataYoutube.map((item) => ({
        text: item.client.name,
        value: item.client._id,
      })),
      onFilter: (value, record) => record.client._id === value,
      render: (_, record) => {
        return (
          <div
            onClick={() => {
              setSelectedItem(record);
              setOpenView(true);
              form.setFieldsValue(record);
              form.setFieldsValue({
                client: record.client._id,
                payment_date: moment(new Date(record.payment_date)),
                _id: record._id,
              });
            }}
            className="text-undrline text-blue-500 cursor-pointer hover:text-blue-700 hover:underline "
          >
            {record.client.name}
          </div>
        );
      },
    },
    {
      title: "N° Paiement",
      dataIndex: "payment_number",
      key: "payment_number",
      filters: dataYoutube
        .reduce((unique, item) => {
          return unique.includes(item.payment_number)
            ? unique
            : [...unique, item.payment_number];
        }, [])
        .map((payment_number) => ({
          text: payment_number,
          value: payment_number,
        })),
      onFilter: (value, record) => record.payment_number === value,
      sorter: (a, b) => a.payment_number.localeCompare(b.payment_number),
    },
    {
      title: "Date",
      dataIndex: "payment_date",
      key: "payment_date",
      sorter: (a, b) => new Date(a.payment_date) - new Date(b.payment_date),
      filter: (value, record) =>
        new Date(record.payment_date).toLocaleDateString().includes(value),
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Montant",
      dataIndex: "payment_amount",
      key: "payment_amount",
      sorter: (a, b) => a.payment_amount - b.payment_amount,
      render: (text) =>
        parseFloat(text).toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        }),
    },
    {
      title: "A Payé ",
      dataIndex: "payment_amount",
      key: "payment_amount",
      render: (_, record) =>
        parseFloat(record.payment_amount * record.commission).toLocaleString(
          "fr-FR",
          {
            style: "currency",
            currency: "EUR",
          }
        ),
    },
    {
      title: "% Createur",
      dataIndex: "commission",
      key: "commission",
      render: (text) => text * 100 + "%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Paid", value: true },
        { text: "Not Paid", value: false },
      ],
      render: (text) =>
        text ? (
          <Tag title="Payé" color="green">
            {" "}
            Payé
          </Tag>
        ) : (
          <Tag title="Non Payé" color="red">
            Non Payé
          </Tag>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex">
          <Eye
            className="text-emerald-500 hover:text-emerald-700 mr-2 cursor-pointer"
            onClick={() => {
              setSelectedItem(record);
              setOpenView(true);
              form.setFieldsValue(record);
              form.setFieldsValue({
                client: record.client._id,
                payment_date: moment(new Date(record.payment_date)),
                _id: record._id,
              });
            }}
          />

          <FilePenLine
            className="text-sky-500 hover:text-sky-700 mr-2 cursor-pointer"
            onClick={() => {
              form.setFieldsValue(record);
              setIdSelected(record._id);
              form.setFieldsValue({
                client: record.client._id,
                payment_date: moment(new Date(record.payment_date)),
                _id: record._id,
              });
              setIsUpdate(true);
              setOpenModal(true);
            }}
          />

          <Popconfirm
            title="Es tu sure de supprimé ce Paiement?"
            onConfirm={() => {
              onDelete(record._id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Trash2 className="text-red-500 hover:text-red-700 cursor-pointer" />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onDelete = async (id) => {
    await deleteYoutubeApi(id).then(() => {
      message.success("Paiement supprimé avec succès");
      getYoutubePaiements();
    });
  };

  const getYoutubePaiements = async () => {
    setLoading(true);
    await getAllYoutubeApi().then((response) => {
      setDataYoutube(response.data);
      console.log("response", response);
    });
  };

  const getClients = async () => {
    setLoading(true);
    await getAllClient().then((response) => {
      setDataClient(response.data.clients);
      console.log("response", response);
    });
  };

  const addYoutube = async (values) => {
    if (!isUpdate) {
      await addYoutubeApi(values).then((response) => {
        console.log("response", response);
        getYoutubePaiements();
        form.resetFields();
        setOpenModal(false);
      });
    } else {
      await updateYoutubeApi(idSelected, values).then((response) => {
        getYoutubePaiements();
        form.resetFields();
        setOpenModal(false);
        setIsUpdate(false);
      });
    }
  };

  const updateStatus = async (values) => {
    await updateYoutubeApi(selectedItem._id, values).then((response) => {
      getYoutubePaiements();
      setOpenView(false);
      setIsUpdate(false);
    });
  };

  useEffect(() => {
    getClients();
    getYoutubePaiements();
  }, []);

  const groupedData = dataYoutube.reduce((acc, item) => {
    if (!acc[item.payment_number]) {
      acc[item.payment_number] = {
        ...item,
        payment_amount: parseFloat(item.payment_amount),
      };
    } else {
      acc[item.payment_number].payment_amount += parseFloat(
        item.payment_amount
      );
    }
    return acc;
  }, {});

  const list = Object.values(groupedData);

  console.log("List", list);

  return (
    <main className="max-w-7xl px-4 lg:p-10 mt-5 ">
      <div className="flex justify-center -mt-10">
        <Image
          width={200}
          src={youtubelogo}
          alt="youtube logo"
          preview={false}
        />
      </div>
      <div className="lg:flex lg:justify-between ">
        <h1 className="text-3xl first-letter:text-red-500 font-bold text-slate-900 mb-4">
          Paiement Youtube
        </h1>
        <button
          onClick={() => {
            setOpenModal(true);
            form.setFieldsValue({
              payment_number: generateId(),
              payment_date: null,
              payment_amount: null,
              payment_currency: "EUR",
              rate: 0.95,
              client: "",
              commission: 0.8,
              status: false,
              note: "",
            });
          }}
          className="bg-red-500 hover:bg-red-700 h-10 text-white font-bold py-2 px-4 rounded"
        >
          Create a new payment
        </button>
      </div>

      <Table
        columns={columns}
        dataSource={dataYoutube}
        rowKey="_id"
        scroll={{ x: 800 }}
        size="small"
        // loading={loading}
      />

      <div className="flex lg:justify-end my-4">
        <button
          onClick={() => setOpenModalClient(true)}
          className="text-red-500 hover:bg-red-700  hover:text-white h-10  font-bold py-2 px-4 border-lg"
        >
          Voir les users
        </button>
      </div>

      <h1 className="text-3xl first-letter:text-red-500 font-bold text-slate-900 mb-4">
        Historique Paiement
      </h1>
      <div className="grid italic grid-cols-3 max-w-lg font-bold text-orange-600 ">
        <div> Date</div>
        <div> Ref</div>
        <div> Total Reçu</div>
      </div>
      {Object.values(groupedData).map((item) => (
        <div className="grid grid-cols-3 max-w-lg">
          <div>{moment(item.createdAt).format("DD.MM.YYYY")} </div>
          <div>{item.payment_number} </div>
          <div className="font-mono">
            {parseFloat(item.payment_amount * item.rate).toLocaleString(
              "fr-FR",
              {
                style: "currency",
                currency: item.payment_currency,
              }
            )}
          </div>
        </div>
      ))}

      <Modal
        title="Voir les Créateurs"
        open={openModalClient}
        onOk={addYoutube}
        onCancel={() => {
          form.resetFields();
          setOpenModalClient(false);
        }}
        width={600}
        footer={null}
      >
        <div>
          <ul>
            {clientYoutube.map((item) => (
              <li key={item.id}>
                <button className="text-red-500 hover:text-red-700 mr-2">
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Modal>

      <Modal
        title="Création nouveau paiement"
        open={openModal}
        onOk={addYoutube}
        onCancel={() => {
          form.resetFields();
          setOpenModal(false);
        }}
        width={600}
        footer={null}
      >
        <YoutubeForm
          generateId={generateId}
          form={form}
          addYoutube={addYoutube}
          dataClient={dataClient}
        />
      </Modal>

      <Modal
        title=""
        open={openView}
        onCancel={() => {
          form.resetFields();
          setOpenView(false);
        }}
        width={600}
        footer={null}
      >
        <YoutubeView
          setOpenView={setOpenView}
          data={selectedItem}
          form={form}
          updateStatus={updateStatus}
        />
      </Modal>
    </main>
  );
}
