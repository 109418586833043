import Landing from "./Landing";
import Animation from "../../components/shared/Animation";
import LinkShop from "./LinkShop";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { currentToken } from "../../redux/store/authStore";

export default function Home() {
  return (
    <div className="min-h-screen mx-auto lg:px-22 p-10 bg-slate-900 text-slate-50">
      {/* <Animation /> */}
      <Landing />

      {/* <LinkShop />
      <SectionTwo />
      <FeatureSection />
      <SectionThree />
      <SectionFour /> */}
    </div>
  );
}
