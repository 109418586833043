/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPayoutByRef } from "../api/billApi";
import TablePay from "./Tables/TablePay";
import moment from "moment";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

// let coef = 0.85;

const DetailBills = () => {
  const { id } = useParams();

  const [dataBill, setDataBill] = useState([]);

  const getDetail = async () => {
    await getPayoutByRef(id).then((res) => {
      console.log("Bill Info", res.data.data);
      setDataBill(res.data.data);

      // let ids = [];
      // if (!!dataBill) {
      //   dataBill?.map((t) => {
      //     return ids.push({ id: t.facebook_Id, name: t.facebook_name });
      //   });
      //   console.log("ids", ids);
      // }
    });
  };

  useEffect(() => {
    getDetail();
  }, []);

  const statuses = {
    Paid: "text-green-700 bg-green-50 ring-green-600/20",
    Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
    Overdue: "text-red-700 bg-red-50 ring-red-600/10",
  };

  return (
    <div className="px-1">
      <div className="mt-10">
        <div className="text-center mt-10 text-3xl font-bold">
          <span> {moment(dataBill?.payment_date).format("MMMM - YYYY")} </span>
        </div>
      </div>

      <div className="mt-10">
        <h1>Historique des Paiements</h1>
      </div>

      <TablePay data={dataBill} />
    </div>
  );
};

export default DetailBills;
