import { navigationFooter } from "../../utils/constants/navigations";
import moment from "moment";
import { Link } from "react-router-dom";

export default function Footer() {
  const year = moment()._d.getFullYear();

  return (
    <footer className="bg-slate-900">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        {/* <nav
          className="hidden  sm:flex flex-wrap  sm mx-5 -my-2 justify-center"
          aria-label="Footer"
        >
          {navigationFooter.main.map((item) => (
            <div key={item.id} className="px-5 py-2">
              <Link
                to={item.to}
                className="text-base text-gray-300 hover:text-gray-200 uppercase"
              >
                {item.name}
              </Link>
            </div>
          ))}
        </nav> */}
        <div className="mt-4 flex justify-center space-x-6">
          {navigationFooter.social.map((item) => (
            <div key={item.id}>
              <a
                key={item.id}
                href={item.href}
                className="text-gray-400 hover:text-blue-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            </div>
          ))}
        </div>
        <p className="mt-4 text-center text-base text-gray-400 ">
          &copy; {year} Gestion Meta, Tous droits réservés © - Made by
          <a
            href="https://www.laguidev.com"
            className="hover:underline hover:text-red-400"
          >
            LaguiDev.
          </a>
        </p>
        <div className="text-center mt-2 text-yellow-200 underline">
          <Link to={"/login"}>Login Admin </Link>{" "}
        </div>
      </div>
    </footer>
  );
}
