import axios from "axios";
import "../axios";

export const addYoutubeApi = async (form) => {
  let data = await axios.post("/youtube", form);
  return data;
};

export const addYoutubeMonth = async (form) => {
  let data = await axios.post("/youtube/month", form);
  return data;
};

export const getAllYoutubeApi = async () => {
  let data = await axios.get("/youtube");
  return data;
};

export const updateYoutubeApi = async (id, form) => {
  let data = await axios.patch(`/youtube/${id}`, form);
  return data;
};

export const getYoutubeInfoApi = async (id) => {
  let data = await axios.get(`/youtube/${id}`);
  return data;
};

export const getPayoutByRef = async (id) => {
  let data = await axios.get(`/youtube/reference/${id}`);
  return data;
};

export const getYoutubeByFacebook = async (id) => {
  let data = await axios.get(`/youtube/by-facebook/${id}`);
  return data;
};

export const getYoutubeByPeriod = async (id) => {
  let data = await axios.get(`/info-by-period/${id}`);
  return data;
};

export const deleteYoutubeApi = async (id) => {
  let data = await axios.delete(`/youtube/${id}`);
  return data;
};

export const sendYoutube = async (form) => {
  let data = await axios.post(`/youtube/send-youtube`, form);
  return data;
};
