import axios from "axios";
// import { useSelector } from "react-redux";
// import { currentToken } from "./redux/store/authStore";

// let tokenData = null;
// function Test() {
//   tokenData = useSelector(currentToken);
//   console.log("🔴tokenData", tokenData);
//   return tokenData;
// }

axios.defaults.baseURL = `${process.env.REACT_APP_API}/api`;
axios.defaults.withCredentials = false;

const token = localStorage.getItem("token");

if (token) {
  axios.defaults.headers["x-access-token"] = token;
}
axios.defaults.headers["Content-type"] = "application/json";
