import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const monthNames = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "décembre",
];

export default function ChartsBarMonth({ day, month }) {
  const getMonthName = (d) => {
    let data = monthNames[d.getMonth()];
    return data;
  };

  let monthData = month.map((element) => {
    return {
      id: 0,
      MontantTotal: element.MontantTotal,
      label: getMonthName(new Date(element._id)),
    };
  });

  monthData = monthData.sort((a, b) => a.date - b.date);

  const options = {
    chart: {
      type: "column", // Utilisez 'column' pour des barres verticales, 'bar' pour des barres horizontales
    },
    title: {
      text: "Activité mensuelle",
    },
    xAxis: {
      categories: monthData.map((data) => data.label),
      title: {
        text: "Mois",
      },
    },
    yAxis: {
      min: 100,
      title: {
        text: "Montant total",
      },
    },
    series: [
      {
        name: "Paiement Reçu",
        data: monthData.map((data) => data.MontantTotal),
      },
    ],
  };

  return (
    <div>
      <div className="grid grid-cols-1">
        <div style={{ width: "100%", height: "100%" }}>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </div>
    </div>
  );
}
