import axios from "axios";
import "../axios";

export const addPaiement = async (form) => {
  let data = await axios.post("/paiements/", form);
  return data;
};
export const addPaiementBulk = async (form) => {
  let data = await axios.post("/bill/upload", form);
  return data;
};
export const addPaiementFromFile = async (form) => {
  let data = await axios.post("/paiements/upload", form);
  return data;
};

export const getAllPaiement = async () => {
  let data = await axios.get("/paiements/");
  return data;
};

export const updateTabinBill = async (form) => {
  let data = await axios.patch(`/paiements`, form);
  return data;
};

export const updateCommade = async (id, form) => {
  let data = await axios.patch(`/paiements/${id}`, form);
  return data;
};

export const getPaiementInfo = async (id) => {
  let data = await axios.get(`/paiements/${id}`);
  return data;
};

export const getPaiementByName = async (id) => {
  let data = await axios.get(`/paiements/detail/${id}`);
  return data;
};

export const deletePaiement = async (id) => {
  let data = await axios.delete(`/paiements/${id}`);
  return data;
};

export const sendPaiement = async (form) => {
  let data = await axios.post(`/paiements/send-paiement`, form);
  return data;
};
