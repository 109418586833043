/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";

const devisSlice = createSlice({
  name: "devisStore",
  initialState: {
    devis: [],
  },
  reducers: {
    addDevis: (state, action) => {
      console.log("STATE", state.devis.length);
      let array = [];
      array = state.devis;
      let newDevis = action.payload;
      array[array.length] = newDevis;
      state.devis = array;
      return state;
    },
    deleteDevis: (state, action) => {
      state.devis = state.devis.filter((t) => t.id !== action.payload);
      return state;
    },
    updateQuantite: (state, action) => {
      console.log("STATE", state.devis);
      state.devis.map((el) => {
        if (el.id === action.payload.id) {
          el.quantite = action.payload.quantite;
        }
      });
      return state;
    },
    resetDevis: (state, action) => {
      state.devis = [];
      return state;
    },
  },
});

export default devisSlice.reducer;

export const { addDevis, deleteDevis, updateQuantite, resetDevis } =
  devisSlice.actions;
