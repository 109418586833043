import React from "react";
import FormAbout from "../../components/About/FormAbout";
import HeaderAbout from "../../components/About/HeaderAbout";

const About = () => {
  return (
    <div>
      <HeaderAbout />
      <FormAbout />
    </div>
  );
};

export default About;
