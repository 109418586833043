import axios from "axios";
import "../axios";

export const addBill = async (form) => {
  let data = await axios.post("/bill", form);
  return data;
};

export const addBillMonth = async (form) => {
  let data = await axios.post("/bill/month", form);
  return data;
};

export const addPayout = async (form) => {
  let data = await axios.post("/bill/payout", form);
  return data;
};

export const addBillBulk = async (form) => {
  let data = await axios.post("/bill/upload", form);
  return data;
};

export const getAllBill = async () => {
  let data = await axios.get("/bill");
  return data;
};

export const updateBillApi = async (id, form) => {
  let data = await axios.patch(`/bill/${id}`, form);
  return data;
};

export const getBillInfo = async (id) => {
  let data = await axios.get(`/bill/${id}`);
  return data;
};

export const getPayoutByRef = async (id) => {
  let data = await axios.get(`/bill/reference/${id}`);
  return data;
};

export const getBillByFacebook = async (id) => {
  let data = await axios.get(`/bill/by-facebook/${id}`);
  return data;
};

export const getBillByPeriod = async (id) => {
  let data = await axios.get(`/info-by-period/${id}`);
  return data;
};

export const deleteBillApi = async (id) => {
  let data = await axios.delete(`/bill/${id}`);
  return data;
};

export const sendBill = async (form) => {
  let data = await axios.post(`/bill/send-bill`, form);
  return data;
};
