import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getClientInfo } from "../../api/clientApi";
import { getPaiementByName } from "../../api/paiementApi";
import { Table, Row, Tag } from "antd";
import { color } from "highcharts";

export default function DetailClient() {
  const { id } = useParams();
  console.log("id", id);
  const [clientData, setClientData] = useState({});
  const [paiementData, setPaiementData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [facebookId, setFacebookId] = useState();

  const getDetailClient = async () => {
    setLoading(true);
    await getClientInfo(id)
      .then((res) => {
        console.log("res.data", res.data.clients);
        setClientData(res.data.clients);
        setFacebookId(res.data.clients.facebook_Id);
      })
      .then((res) => {
        console.log("res", res);
        if (!!facebookId) setLoading(false);
        getPaiementByName(facebookId).then((res) => {
          console.log("res.data", res.data);
          setPaiementData(res.data);
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getDetailClient();
  }, [facebookId]);

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Payé", value: true },
        { text: "Non Payé", value: false },
      ],
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status - b.status,
      render: (text, record) => (
        <div className="flex">
          {record.status ? (
            <Tag className="ml-5 flex items-center" color="green">
              Payé
            </Tag>
          ) : (
            <Tag className="ml-5 flex items-center" color="red">
              Non Payé
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "Facebook Name",
      dataIndex: "facebook_name",
      key: "facebook_name",
    },
    {
      title: "Période",
      dataIndex: "payout_period_start",
      key: "payout_period_start",
      render: (text, record) =>
        `${record.payout_period_start}  ${record.payout_period_end}`,
    },
    {
      title: "",
      dataIndex: "remittance",
      key: "remittance",

      render: (text) =>
        parseFloat(text).toLocaleString("fr-FR", {
          style: "currency",
          currency: "USD",
        }),
    },
    // {
    //   title: "status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text, record) => (
    //     <Row>
    //       {record.status ? (
    //         <Tag color="green">Payé</Tag>
    //       ) : (
    //         <Tag color="red">Non Payé</Tag>
    //       )}
    //     </Row>
    //   ),
    // },
  ];

  const data = paiementData?.data;

  return (
    <div className="p-5">
      <div className="">
        <h1 className="text-xl text-center "> {clientData?.facebook_name} </h1>
        <div className="">
          <h4 className="text-md text-center text-slate-500 ">
            {clientData?.facebook_Id}{" "}
          </h4>
        </div>

        <p className="text-slate-900 mt-10 mb-5 ">
          {" "}
          Total depuis Toujours :{" "}
          <span className="text-xl">
            {/* {paiementData?.total_remittance}{" "} */}
            {paiementData.total_remittance &&
              parseFloat(paiementData?.total_remittance)?.toLocaleString(
                "fr-FR",
                { style: "currency", currency: "USD" }
              )}{" "}
          </span>
        </p>
        <Row className="flex justify-center">
          <Table columns={columns} dataSource={data} rowKey="facebook_Id" />
        </Row>
      </div>
    </div>
  );
}
