import React, { useState, useEffect } from "react";

import {
  CurrencyDollarIcon,
  DocumentIcon,
  ShoppingCartIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { getStatistique } from "../../api/statistiqueApi";
import Loader from "../../components/shared/Loader";
import GraphDay from "../../components/dashboard/GraphDay";
import formatMoney from "../../utils/FormatMoney";
import GraphMonth from "../../components/dashboard/GraphMonth";
import { currentToken } from "../../redux/store/authStore";
import { useSelector } from "react-redux";
import ChartsMonth from "../../components/dashboard/ChartsMonth";
import ChartsBarMonth from "../../components/dashboard/ChartsBarMonth";

export default function Dashboard() {
  const [statistique, setStatistique] = useState();
  const [loading, setLoading] = useState(true);

  const tokenData = useSelector(currentToken);
  console.log("tokenData", tokenData);
  useEffect(() => {
    if (tokenData) {
      localStorage.setItem("token", tokenData);
    }
  }, []);

  useEffect(() => {
    const getStatis = async () => {
      const data = await getStatistique();
      console.log("STAT", data.data.statistique);
      setStatistique(data.data.statistique);
      setLoading(false);
    };
    getStatis();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Tableau de bord
            </h1>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="mt-8">
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Résumé de l'activité
                </h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  {/* Card */}
                  <div className="bg-gray-200 border border-black-400 overflow-hidden shadow rounded-lg">
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <DocumentIcon
                            className="h-6 w-6 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            <dt className="text-sm font-medium text-emerald-500 truncate">
                              Nombre de Relevés
                            </dt>
                            <dd>
                              <div className="text-lg font-medium text-emerald-600">
                                {statistique?.totalPaiement}
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-200 border border-black-400 overflow-hidden shadow rounded-lg">
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <CurrencyDollarIcon
                            className="h-6 w-6 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            <dt className="text-sm font-medium text-blue-500 truncate">
                              Nombre de Facture
                            </dt>
                            <dd>
                              <div className="text-lg font-medium text-blue-600">
                                {statistique?.totalBill}
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-200 border border-black-400 overflow-hidden shadow rounded-lg">
                    <div className="p-5">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <UsersIcon
                            className="h-6 w-6 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-5 w-0 flex-1">
                          <dl>
                            <dt className="text-sm font-medium text-red-500 truncate">
                              Nombre de Clients
                            </dt>
                            <dd>
                              <div className="text-lg font-medium text-red-600">
                                {statistique?.totalClient}
                              </div>
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                    {/* <div className="bg-gray-200 px-5 py-3">
                      <div className="text-sm">
                        <a
                          // href={card.href}
                          className="font-medium text-cyan-700 hover:text-cyan-900"
                        ></a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
              Activités récente
            </h2>

            {/* <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="pt-5 grid grid-col">
                <div className="flex justify-end">Montant Total HT</div>
                <div className="font-bold flex justify-end text-green-800">
                  <p className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                    {formatMoney(statistique?.MontantHt)} €
                  </p>
                </div>
              </div>
            </div> */}
            <div className="mt-5">
              <div className="mt-5">
                <ChartsMonth
                  day={statistique?.dayGraph}
                  month={statistique?.monthGraph}
                />
              </div>

              <div className="mt-5">
                <ChartsBarMonth
                  day={statistique?.dayGraph}
                  month={statistique?.yearGraph}
                />
              </div>
              {/* <div className="mt-5">
                <GraphMonth
                  day={statistique?.dayGraph}
                  month={statistique?.monthGraph}
                />
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
